import JSON5 from "json5"
import React from "react"
import {useSelector} from "react-redux"
import {useHistory} from "react-router-dom"
import SettingsIcon from "@material-ui/icons/Settings"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import makeStyles from "@material-ui/core/styles/makeStyles"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import {Box, MenuItem, MenuList, Paper, Popover, Typography} from "@material-ui/core"
import SidebarToggleHandlerMod from "../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandlerMod"


const useStyles = makeStyles(theme => ({
    root: {
        padding: "30px 16px 12px 16px",
        borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`
    },
    userInfo: {
        paddingTop: 24,
        transition: "all 0.1s ease",
        height: 75,
        opacity: 1,
        ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
            height: 0,
            paddingTop: 0,
            opacity: 0,
            transition: "all 0.3s ease"
        }
    },
    userTitle: {
        color: theme.palette.sidebar.textDarkColor,
        marginBottom: 8
    },
    userSubTitle: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 0.25
    }
}))

const SidebarHeader = () => {
    const classes = useStyles()
    const history = useHistory()

    const [anchorEl, setAnchorEl] = React.useState(null)
    const {authUser} = useSelector(({auth}) => auth)

    let settings = authUser["settings"]
    settings = JSON5.parse(settings)
    const website_list = settings["website_list"]
    const website_logout_url = `https://${settings["website_logout_url"]}`

    const handlePopoverOpen = event => {
        setAnchorEl(document.getElementById("ps"))
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    const onLogoutClick = () => {
        handlePopoverClose()
        window.location.href = website_logout_url
    }

    const onSettingsClick = () => {
        handlePopoverClose()
        history.push("/extra-pages/manage-profile")
    }

    return (
        <Box className={classes.root}>
            <SidebarToggleHandlerMod edge="start" color="inherit" aria-label="menu"/>
            <Box className={classes.userInfo} id="ps">
                <Box
                    className="pointer"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <Box mr={2}>
                        <Typography
                            className={classes.userTitle}
                            component="h3"
                            variant="h6"
                        >
                            {authUser ? authUser.name : ''}
                        </Typography>
                        <Typography className={classes.userSubTitle} id="user-email">
                            {authUser ? authUser.email : ''}
                        </Typography>
                    </Box>
                    {website_list.length > 1 && <ArrowDropDownIcon onClick={handlePopoverOpen}/>}
                </Box>
            </Box>

            {open && (
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    container={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "left"
                    }}
                >
                    <Paper elevation={8} style={{width: 220}}>
                        <MenuList>
                            <MenuItem onClick={onSettingsClick} style={{width: "100%"}}>
                                <SettingsIcon/>
                                <Box ml={2} style={{width: "100%"}}>Settings</Box>
                            </MenuItem>
                            <MenuItem onClick={onLogoutClick}>
                                <ExitToAppIcon/>
                                <Box ml={2}>Logout</Box>
                            </MenuItem>
                        </MenuList>
                    </Paper>
                </Popover>
            )}
        </Box>
    )
}

export default SidebarHeader
