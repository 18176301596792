import JSON5 from "json5"
import {useSelector} from "react-redux"
import React, {useEffect, useState} from "react"
import {ModeOfTravel} from "@mui/icons-material"
import PerfectScrollbar from "react-perfect-scrollbar"
import makeStyles from "@material-ui/core/styles/makeStyles"
import CmtVertical from "../../../../../@coremat/CmtNavigation/Vertical"
import {
    Chat,
    Contacts,
    Group,
    Home,
    TrendingUp,
    PermContactCalendar,
    FlipToBack,
    Storage,
    Toc,
    PieChart,
    Business,
    ListAlt,
    DynamicFeed,
    PermIdentity,
    RecentActors,
    Description,
    ExitToApp,
    Build,
    DataUsage,
    AccountBalanceWallet,
    ContactSupport,
    MenuBook,
    FormatIndentIncrease,
    FormatIndentDecrease,
    FormatLineSpacing,
} from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
    perfectScrollbarSidebar: {
        height: "100%",
        transition: "all 0.3s ease",
        ".Cmt-sidebar-fixed &, .Cmt-Drawer-container &": {
            height: "calc(100% - 167px)"
        },
        ".Cmt-modernLayout &": {
            height: "calc(100% - 72px)"
        },
        ".Cmt-miniLayout &": {
            height: "calc(100% - 91px)"
        },
        ".Cmt-miniLayout .Cmt-sidebar-content:hover &": {
            height: "calc(100% - 167px)"
        }
    }
}))

const SideBar = () => {
    const classes = useStyles()

    const {authUser} = useSelector(({auth}) => auth)
    const [navigationMenus, setNavigationMenus] = useState([])

    useEffect(() => {
        if (authUser) {
            let settings = authUser["settings"]
            settings = JSON5.parse(settings)

            const menuGroup = settings["menuGrouping"]
            const menuActive = settings["menuActive"]

            let navigationMenus = []

            menuGroup.forEach((group) => {
                let groupName = group["name"]
                let options = group["options"]

                let menuLinks = []

                options.forEach((option) => {
                    if (option === "home")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <Home/>,
                            type: "item",
                            link: "/home"
                        })
                    else if (option === "trends")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <TrendingUp/>,
                            type: "item",
                            link: "/extra-pages/trends"
                        })
                    else if (option === "import")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <FormatIndentIncrease/>,
                            type: "item",
                            link: "/extra-pages/import"
                        })
                    else if (option === "export")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <FormatIndentDecrease/>,
                            type: "item",
                            link: "/extra-pages/export"
                        })
                    else if (option === "crm")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <PermContactCalendar/>,
                            type: "item",
                            link: "/extra-pages/crm"
                        })
                    else if (option === "enrich")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <FormatLineSpacing/>,
                            type: "item",
                            link: "/extra-pages/enrich"
                        })
                    else if (option === "smc")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <FlipToBack/>,
                            type: "item",
                            link: "/extra-pages/smc"
                        })
                    else if (option === "oct")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <Storage/>,
                            type: "item",
                            link: "/extra-pages/oct"
                        })
                    else if (option === "cct")
                        menuLinks.push({
                            name: menuActive[option],
                            type: "item",
                            icon: <Toc/>,
                            link: "/apps/competitive-research"
                        })
                    else if (option === "mpa")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <PieChart/>,
                            type: "item",
                            link: "/extra-pages/mpa"
                        })
                    else if (option === "cst")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <Business/>,
                            type: "item",
                            link: "/extra-pages/cst"
                        })
                    else if (option === "profiling")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <Group/>,
                            type: "item",
                            link: "/extra-pages/profiling"
                        })
                    else if (option === "forms")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <ListAlt/>,
                            type: "item",
                            link: "/extra-pages/forms"
                        })
                    else if (option === "prospects")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <Contacts/>,
                            type: "item",
                            link: "/data/prospects"
                        })
                    else if (option === "updates")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <DynamicFeed/>,
                            type: "item",
                            link: "/extra-pages/updates"
                        })
                    else if (option === "profile")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <PermIdentity/>,
                            type: "item",
                            link: "/extra-pages/manage-profile"
                        })
                    else if (option === "users")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <RecentActors/>,
                            type: "item",
                            link: "/data/localseo-accounts"
                        })
                    else if (option === "orders")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <Description/>,
                            type: "item",
                            link: "/extra-pages/view-orders"
                        })
                    else if (option === "logout")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <ExitToApp/>,
                            type: "item",
                            link: "/mijn-account/customer-logout"
                        })
                    else if (option === "handbook")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <MenuBook/>,
                            type: "item",
                            link: "/extra-pages/handbook"
                        })
                    else if (option === "tool")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <Build/>,
                            type: "item",
                            link: "/extra-pages/support-tool"
                        })
                    else if (option === "data")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <DataUsage/>,
                            type: "item",
                            link: "/extra-pages/support-data"
                        })
                    else if (option === "finance")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <AccountBalanceWallet/>,
                            type: "item",
                            link: "/extra-pages/support-finance"
                        })
                    else if (option === "chat")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <Chat/>,
                            type: "item",
                            link: "/extra-pages/chat"
                        })
                    else if (option === "contact")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <ContactSupport/>,
                            type: "item",
                            link: "/extra-pages/contact"
                        })
                    else if (option === "localseo")
                        menuLinks.push({
                            name: menuActive[option],
                            icon: <ModeOfTravel/>,
                            type: "item",
                            link: "/data/localseo"
                        })
                })

                if (menuLinks.length > 0) {
                    navigationMenus.push(
                        {
                            name: groupName,
                            type: "section",
                            children: menuLinks
                        }
                    )
                }
            })

            setNavigationMenus(navigationMenus)
        }
    }, [authUser])

    return (
        <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
            <CmtVertical menuItems={navigationMenus}/>
        </PerfectScrollbar>
    )
}

export default SideBar
