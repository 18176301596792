import React from "react"
import JSON5 from "json5"
import axios from "./config"
import ProspectsAPI from "../../api/prospects"
import handleFooterData, {initFooterData} from "../../common"
import {fetchError, fetchStart, fetchSuccess} from "../../../redux/actions"
import {
    setAuthUser,
    setForgetPassMailSent,
    updateLoadUser
} from "../../../redux/actions/Auth"


const JWTAuth = {
    onRegister: ({name, email, password}) => {
        return dispatch => {
            dispatch(fetchStart())
            axios
                .post("auth/register", {
                    email: email,
                    password: password,
                    name: name
                })
                .then(({data}) => {
                    if (data.result) {
                        localStorage.setItem("token", data.token.access_token)
                        axios.defaults.headers.common["Authorization"] = "Bearer " + data.token.access_token
                        dispatch(fetchSuccess())
                        dispatch(JWTAuth.getAuthUser(true, data.token.access_token))
                    } else {
                        dispatch(fetchError(data.error));
                    }
                })
                .catch(function (error) {
                    dispatch(fetchError(error.message))
                })
        }
    },

    onLogin: ({email, password}) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post("auth/login", {
                        email: email,
                        password: password
                    })
                    .then(({data}) => {
                        if (data.result) {
                            localStorage.setItem("token", data.token.access_token);
                            axios.defaults.headers.common["Authorization"] =
                                "Bearer " + data.token.access_token;
                            dispatch(fetchSuccess());
                            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
                        } else {
                            dispatch(fetchError(data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.message));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    onLogout: () => {
        return dispatch => {
            dispatch(fetchStart());
            axios
                .post("auth/logout")
                .then(({data}) => {
                    if (data.result) {
                        dispatch(fetchSuccess());
                        localStorage.removeItem("token");
                        localStorage.removeItem("templateSettings");
                        dispatch(setAuthUser(null));
                    } else {
                        dispatch(fetchError(data.error));
                    }
                })
                .catch(function (error) {
                    dispatch(fetchError(error.message));
                });
        };
    },
    getAuthUser: (loaded = false) => {
        return dispatch => {
            dispatch(fetchStart())
            dispatch(updateLoadUser(loaded))

            const url = new URL(window.location.href)
            const user_key = url.searchParams.get("user_key") === null ? "" : url.searchParams.get("user_key")
            const user_api = url.searchParams.get("user_api") === null ? "" : url.searchParams.get("user_api")

            axios
                .request({
                    method: "GET",
                    params: {
                        action: "get_token",
                        user_key: user_key
                    }
                })
                .then(({data}) => {
                    let authData = data.response

                    authData.user_key = user_key
                    authData.user_api = user_api

                    let settings = JSON5.parse(authData["settings"])
                    if (data.msg === "authorised") {
                        // GET Settings
                        axios
                            .request({
                                method: "GET",
                                params: {
                                    action: "get_settings",
                                    t: authData["token"],
                                    l: "nl",
                                    u: 0
                                }
                            })
                            .then(({data}) => {
                                settings = {...settings, ...data.response}

                                let website = settings.website
                                authData.settings = JSON5.stringify(settings)

                                initFooterData(settings, dispatch, false)

                                let menuStart = settings["menuStart"]
                                if (menuStart === "prospects") {
                                    ProspectsAPI
                                        .updatePrice(authData["token"], settings["prospects_section_1"], "basic", "start")
                                        .then((data) => {
                                            dispatch(fetchSuccess())
                                            dispatch(setAuthUser({
                                                authData: authData,
                                                token: authData["token"],
                                                orderID: settings["prospects_section_1"],
                                                config: data,
                                                website: website
                                            }))
                                            handleFooterData(data["order"]["footer"], dispatch, false)
                                        })
                                } else {
                                    dispatch(fetchSuccess())
                                    dispatch(setAuthUser({
                                        authData: authData,
                                        token: authData["token"],
                                        orderID: settings["prospects_section_1"],
                                        config: null,
                                        website: website
                                    }))
                                }
                            })
                    } else dispatch(updateLoadUser(true))
                })
                .catch(function (error) {
                    dispatch(updateLoadUser(true))
                })
        }
    },
    onForgotPassword: () => {
        return dispatch => {
            dispatch(fetchStart());

            setTimeout(() => {
                dispatch(setForgetPassMailSent(true));
                dispatch(fetchSuccess());
            }, 300);
        };
    },
    getSocialMediaIcons: () => {
        return <React.Fragment> </React.Fragment>;
    }
};

export default JWTAuth
