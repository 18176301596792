import clsx from "clsx"
import JSON5 from "json5"
import {useSelector} from "react-redux"
import {Hidden} from "@material-ui/core"
import Footer from "../../partials/Footer"
import SideBar from "../../partials/SideBar"
import ContentLoader from "../../../ContentLoader"
import SidebarHeader from "../../partials/SidebarHeader"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React, {useContext, useEffect, useState} from "react"
import CmtHeader from "../../../../../@coremat/CmtLayouts/Vertical/Header"
import CmtFooter from "../../../../../@coremat/CmtLayouts/Vertical/Footer"
import CmtVerticalLayout from "../../../../../@coremat/CmtLayouts/Vertical"
import CmtSidebar from "../../../../../@coremat/CmtLayouts/Vertical/Sidebar"
import CmtContent from "../../../../../@coremat/CmtLayouts/Vertical/Content"
import {HEADER_TYPE, SIDEBAR_TYPE} from "../../../../constants/ThemeOptions"
import AppContext from "../../../contextProvider/AppContextProvider/AppContext"
import SidebarToggleHandler from "../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler"

const useStyles = makeStyles(theme => ({
    minimalNoHeader: {
        backgroundColor: "transparent",
        boxShadow: "none",
        "& .Cmt-toggle-menu": {
            color: theme.palette.text.primary,
            marginLeft: 15
        }
    }
}))

const MinimalNoHeader = ({className, children}) => {
    const classes = useStyles();
    const {
        drawerBreakPoint,
        isSidebarFixed,
        sidebarStyle,
        sidebarSize
    } = useContext(AppContext);
    const {authUser} = useSelector(({auth}) => auth)
    const [showMenu, setShowMenu] = useState(true)
    const [showFooter, setShowFooter] = useState(false)
    const [headerMargin, setHeaderMargin] = useState(0)

    useEffect(() => {
        if (authUser) {
            let settings = authUser["settings"]
            settings = JSON5.parse(settings)

            setShowMenu(settings["showMenu"])
            setShowFooter(settings["showFooter"])

            setHeaderMargin(parseFloat(settings["header_margin"]))
        }
    }, [authUser])

    return (
        <CmtVerticalLayout
            drawerBreakPoint={showMenu ? drawerBreakPoint : null}
            className={clsx("verticalMinimalNoHeaderLayout", className)}
            sidebarWidth={showMenu ? sidebarSize : 0}
        >
            <CmtHeader className={classes.minimalNoHeader} type={HEADER_TYPE.STATIC}>
                <Hidden lgUp>
                    <SidebarToggleHandler
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                    />
                </Hidden>
            </CmtHeader>
            {
                showMenu ?
                    <CmtSidebar
                        type={SIDEBAR_TYPE.MINI}
                        isSidebarFixed={isSidebarFixed}
                        {...sidebarStyle}
                    >
                        <div style={{
                            width: "100%",
                            background: "#F4F4F7",
                            position: "sticky",
                            top: 0,
                            // z-index of the sidebar is 1103 (src/@jumbo/components/AppLayout/partials/SideBar/index.js). So, this value should be < 1103.
                            // Otherwise, this div will go on top of the sidebar when the sidebar will be expanded.
                            zIndex: 1102,
                            // height: (headerMargin + 10 + 13)
                            height: (headerMargin + 10)
                        }}/>
                        <SidebarHeader/>
                        <SideBar/>
                    </CmtSidebar> : null
            }
            <CmtContent style={{position: "relative"}}>
                {/*<Box style={{width: "100%", border: "2px solid red", backgroundColor: "transparent"}} height={headerMargin}/>*/}
                <div style={{
                    width: "100%",
                    background: "#F4F4F7",
                    position: "sticky",
                    top: 0,
                    // z-index of the sidebar is 1103 (src/@jumbo/components/AppLayout/partials/SideBar/index.js). So, this value should be < 1103.
                    // Otherwise, this div will go on top of the sidebar when the sidebar will be expanded.
                    zIndex: 1102,
                    height: (headerMargin + 10)
                }}/>
                {children}
                <ContentLoader/>
            </CmtContent>
            {showFooter && (
                <CmtFooter type="static">
                    <Footer/>
                </CmtFooter>
            )}
        </CmtVerticalLayout>
    )
}

export default MinimalNoHeader
